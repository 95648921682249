
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import CollapsePanel from '@/components/collapse-panle.vue'
import { BaseProjectInfo } from '@/api/project'
import webApiProjectInfoApi, {
  WebApiProjectInfo
} from '@/api/web-api-project-info'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import { getterProjectId, getterProjectMemberRight } from '@/store'
import { Form } from 'view-design'
import StateDataFormPage from '@/frame/share/state-form-page'
import StateFormItem from '@/components/state-formitem.vue'
import StateEditor from '@/components/state-editor.vue'

@Component({
  name: 'dataPage',
  components: { DataFormPageView, CollapsePanel, StateFormItem, StateEditor }
})
export default class DataPage extends StateDataFormPage<
  WebApiProjectInfo,
  any
> {
  protected serverState = ''

  /**
   * 当前表单数据
   */
  protected formData: WebApiProjectInfo = {}

  project: BaseProjectInfo = {}

  languageList: Array<string> = []
  languageSet: Set<string> = new Set()
  selectLanguage = '' // 选中的语言

  projectId = -1
  projectName = ''

  usedFrontGit = false

  usedAppGit = false

  /**
   * 用户是否可以编辑文档
   */
  get canEdit () {
    const right = getterProjectMemberRight()
    if (!right) return false
    return right.updateProjectInfo
  }

  rules = {
    projectName: [
      { required: true, message: '名称不允许为空', trigger: 'blur' },
      {
        pattern: '[a-zA-Z]{1}[0-9a-zA-Z]+',
        message: '字母与数字的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    title: [{ required: true, message: '标题不允许为空', trigger: 'blur' }],
    orgName: [
      { required: true, message: '组织名不允许为空', trigger: 'blur' },
      {
        pattern: '[a-zA-Z]+[0-9a-zA-Z_]*(\\.[a-zA-Z]+[0-9a-zA-Z_]*)*',
        message: '字母与.或者-的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    language: [
      { required: true, message: '项目语言至少添加一种', trigger: 'blur' }
    ]
  }

  created () {
    // 初始化状态机
    this.initFromState({})
    // 获取路径传递的参数值
    this.projectId = getterProjectId()!
    this.onLoadFormData()
  }

  // 添加语言
  addLanguage () {
    if (!this.selectLanguage) {
      this.$Message.warning('请先选择语言')
      return
    }
    if (this.languageSet.has(this.selectLanguage)) {
      this.$Message.warning(this.selectLanguage + '已存在了，请勿重复选择！')
      return
    }
    this.languageSet.add(this.selectLanguage)
    this.languageList.push(this.selectLanguage)
  }

  // 删除语言选择
  deleteLanguage (index: number) {
    this.languageSet = new Set()
    this.languageList.splice(index, 1)
    this.languageList.forEach(e => {
      this.languageSet.add(e)
    })
  }

  // 拼接选择语言
  saveLanguageToData () {
    let arrStr = ''
    this.languageList.forEach((e, index) => {
      arrStr += e
      if (index < this.languageList.length - 1) {
        arrStr += ','
      }
    })
    this.formData.language = arrStr
  }

  /**
   * 加载指定id的表单数据
   */
  protected onLoadFormData (projectId: number = this.projectId) {
    return new Promise<WebApiProjectInfo>((resolve, reject) => {
      webApiProjectInfoApi
        .getInfoByProjectId(projectId)
        .then(response => {
          if (response.data) {
            this.formData = response.data
            if (this.formData.language) {
              this.languageList = this.formData.language.split(',')
            }
          }
          this.usedFrontGit = !!this.formData.frontGitUrl
          this.usedAppGit = !!this.formData.appGitUrl
          resolve(this.formData)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * 生成新增的表单数据
   */
  protected onCreateNewFormData (): Promise<WebApiProjectInfo> {
    return new Promise<WebApiProjectInfo>((resolve, reject) => {
      resolve({})
    })
  }

  /**
   * 判断新增or保存操作
   */
  onSavePage () {
    // 拼接多语言
    this.saveLanguageToData()

    const form = this.$refs.form as Form
    form.validate(valid => {
      if (!valid) {
        this.$Message.error('数据校验错误，请检查!')
        return
      }
      if (this.formData.id) {
        this.onSaveFormData()
      } else {
        this.onInsertFormData()
      }
    })
  }

  /**
   * 执行新增数据保存
   */
  protected onInsertFormData () {
    return new Promise<WebApiProjectInfo>((resolve, reject) => {
      webApiProjectInfoApi
        .insertItem(this.formData)
        .then(resp => {
          this.formData = resp.data!
          // this.$Notice.success({ title: '成功', desc: '保存成功' })
          resolve(this.formData)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * 保存修改数据
   */
  protected onSaveFormData () {
    return new Promise<WebApiProjectInfo>((resolve, reject) => {
      webApiProjectInfoApi
        .updateItem(this.formData)
        .then(resp => {
          this.formData = resp.data!
          // this.$Notice.success({ title: '成功', desc: '保存成功' })
          resolve(this.formData)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * 表单验证
   */
  protected doFormValidate (callback: (valid?: boolean | undefined) => void) {
    ;(this.$refs.form as Form).validate(valid => callback(valid))
  }
}
